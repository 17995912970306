import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
})
export class DemoModule {}
